.leftSection_texts_logo{ width: 700px; max-width: 100%;}

.leftSection_texts h2{  padding: 0; font-size: 48px; color: #042241; text-transform: uppercase; font-weight: bold; line-height: 50px; margin: 40px 0 0 0;}

.leftSection_texts p{ margin: 20px 0; padding: 0; font-size: 24px; color: #042241;}

.leftSection_texts h3{ margin: 0; padding: 0; font-size: 34px; color: #042241; text-transform: uppercase; font-weight: bold; line-height: 40px;  }


.leftSection_texts_con1{  display: block; 
    background: url('https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/leftSection_texts_shadow1.webp') no-repeat left top; width: 100%;
  padding-top: 33px; margin-top:-20px
} 



.leftSection_texts_con1_BG{ background: url('https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/leftSection_texts_arrow1.webp') no-repeat right top;
 background-size: contain; height: 132px; margin-left: -1%; position: relative;
}
.leftSection_texts_con1 label {
    width: auto;
    height: 132px;
    font-size: 27px;
    color: #1d1e1e;
    line-height: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin: 0 0 0 1%;
}


.leftSection_texts_con2{  display: block; 
    background: url('https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/leftSection_texts_shadow2.webp') no-repeat left top; width: 100%;
  padding-top: 30px; margin-top:-20px
} 



.leftSection_texts_con2_BG{ background: url('https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/leftSection_texts_arrow2.webp') no-repeat right top;
 background-size: contain; height: 233px; margin-left: -1%; position: relative;
}
.leftSection_texts_con2 label {
    width: auto;
    font-size: 40px;
    color: #1d1e1e;
    line-height: 41px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 32px 150px 0 20px;
    margin: 0 0 0 1%;
}

.leftSection_texts { 
    padding-left: 0.5%;
}



 
@media only screen and (max-width: 1820.98px){
    .leftSection_texts {
        width: 55%;
    }
 

 .HomeMain_container .formWrapperMain{ width: 440px;}

 .formWrapperMain .multipleCheckbox .MuiFormControlLabel-root{ width: 48%;}
}

@media only screen and (max-width: 1800.98px){
    .formWrapMain{ margin-right: -2%;}
}


@media only screen and (max-width: 1760.98px){
    .leftSection_texts {
        width: 52%;
    }

    .leftSection_texts_con2 label { 
        font-size: 35px; 
        line-height: 40px; 
        padding: 22px 150px 0 20px; 
    }

    .leftSection_texts_con1 label { 
        font-size: 25px;  
        align-items: inherit;
        padding: 27px 90px 0 20px; 
    }

}

@media only screen and (max-width: 1722.98px){
    .leftSection_texts_con2 label {
        font-size: 30px;
        line-height: 36px;
        padding: 25px 150px 0 20px;
    }
}


@media only screen and (max-width: 1566.98px){
    .formWrapMain {
      background: none; margin: 0;
    }

    .formWrapMain {
        width: 40%;}

    .HomeMain_container .formWrapperMain {
        width: auto;
    }

    .leftSection_texts_con1 label {
        font-size: 22px;
        align-items: inherit;
        padding: 30px 90px 0 20px;
        line-height: 22px;
        height: auto;
    }

    .leftSection_texts_con2 label {
        font-size: 26px;
        line-height: 34px;
        padding: 20px 150px 0 20px;
    }

    .leftSection_texts_con2{ margin-top: -40px;}
}

@media only screen and (max-width: 1460.98px){

    .formWrapMain {
        margin-right: 0; 
        background: none; width: 35%;
    }

    .HomeMain_container .formWrapperMain {
        width: auto;
    }

    .leftSection_texts {
        width: 54%;
    }
     
}

@media only screen and (max-width: 1360.98px){
    .leftSection_texts {
        width: 58%;
    }
}


@media only screen and (max-width: 1300.98px){
    .leftSection_texts {
        width: 60%;
    }
}

@media only screen and (max-width: 1250.98px){
    .leftSection_texts {
        width: 62%;
    }
}

@media only screen and (max-width: 1199.98px){
    .formSction_subWrp {
        display: block; 
        padding-top: 25px;
    }


    .leftSection_texts {
        width: auto;
        padding: 0;
    }

    .HomeMain_container .unilityBlock_main img {
        margin: 35px 0 0 0;
    }

    .formWrapMain {
        margin-right: auto; 
        margin-left: auto;
        width: 99%;
    }

    .leftSection_texts_con1 {
        display: block;
        background: none;
        width: 100%;
        padding-top: 0;
        margin-top: 20px;
    }

    .leftSection_texts_con2 {
        display: block;
        background: none;
        width: 100%;
        padding-top: 0;
        margin-top: 20px; margin-bottom: 30px;
    }

    .leftSection_texts_con1_BG {
        background: rgb(255,226,0);
        background: linear-gradient(180deg, rgba(255,226,0,1) 0%, rgba(255,196,0,1) 100%);
        height: auto;
        margin-left: 0;
        position: relative; border-radius: 4px;
    }

    .leftSection_texts_con2_BG {
        background: rgb(255,226,0);
        background: linear-gradient(180deg, rgba(255,226,0,1) 0%, rgba(255,196,0,1) 100%);
        height: auto;
        margin-left: 0;
        position: relative; border-radius: 4px;
    }


    .leftSection_texts_con1 label { 
        height: auto;
        margin: 0; padding: 15px; font-size: 26px; line-height: 30px;
    }


    .leftSection_texts_con2 label { 
        height: auto;
        margin: 0; padding: 15px; font-size: 30px; line-height: 32px;
    }
}

@media only screen and (max-width: 600.98px){

    .formWrapperMain .multipleCheckbox .MuiFormControl-root {
        display: block;}

        .formWrapperMain .multipleCheckbox .MuiFormControlLabel-root {
            width: auto;
            margin: 0;
        }

}