 
 
  .error-page{
   
    color: #042241;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
  }
  
  .error-page h1{
    margin: 0;
    color: #042241;
    font-size: 120px;
    font-weight: bold;
    text-align: center;
  
  }
  
 
  
  
  .admin_topNav{   background: rgb(125,155,185);
    background: linear-gradient(180deg, rgba(125,155,185,1) 0%, rgba(84,105,126,1) 100%);
      padding: 15px;
      border-radius: 0px;
   
      text-align: center;}
  
  .admin_topNav  button{ color: #fff; font-size: 16px; padding: 5px 12px; text-transform: capitalize;}   
  .admin_topNav  a{ color: #fff; font-size: 16px;}   
  
  .footerblock{font-size: 14px;
 
      color: #737476;
      text-align: center; padding: 20px 5px;
   
       background-color: #191818;}
  
    
    .admin_topbody{background: rgb(226,226,226);
      background: linear-gradient(180deg, rgba(226,226,226,1) 0%, rgba(255,255,255,1) 100%);  padding: 5px 15px; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;     align-items: center;
   }   
  
  
    .admin_topbody .adminlogo{ width: auto; max-width: 180px;}
  
    .topuserblock{ display: flex;
      flex-wrap: wrap;
      justify-content: center;     align-items: center; }
  
  
    .topuserblock h1{ margin: 0; padding: 0 15px; font-size: 18px;}
  
    .topuserblock .PersonIcon {
      background: #25ace3;
      color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 100px;
      padding: 5px;
  }
  .topuserblock Button{ margin: 0; padding: 0; min-width: inherit;}
  
  .topuserblock .userarrowicon{ background-color: #1ea5de;
      color: #fff;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      padding: 0px; margin: 0;}
  
  
      .admin_topNav button.active, .admin_topNav button:hover{ background:#22b2e7; }

.responsive_navBar{ display: none;}

.footerblock span{   margin: 0 5px;}

.footerblock a{   margin: 0 5px;  display: inline-block;    color: #737476;}
.footerblock a:hover{ color: #22b2e7;}

.footerModal .MuiDialog-paper{ margin: 0;
  padding: 0!important; overflow: inherit;   border: 10px solid #0070af!important; background-color: #fff;    position: relative!important;   border-radius: 10px; }

  

.footerModal .MuiTypography-h6{ background: #0070af; font-size: 20px; color: #fff; margin: 0; padding:0 15px 8px 15px;}
  .modallogo{ display: block;  max-width: 90%; margin: 10px auto;}
 

  .comingsoonText{min-width: 500px; max-width: 80%; text-align: center; text-transform: capitalize; font-size: 24px; padding: 15px 0; }


.submenu_wrp .MuiPopover-paper{
    background: rgb(125,155,185);
    background: linear-gradient(180deg, rgba(125,155,185,1) 0%, rgba(84,105,126,1) 100%);

}
.submenu_wrp .MuiPopover-paper a{
font-size: 16px;
font-weight: 500!important;
color: #fff;
}
.submenu_wrp .active a{
  color: #1bbbff;
}


/* .submenu_wrp{
  display: none;
}
.submenu_wrp a:hover{
  background-color: #ddd;
}
.drop_icon:hover .submenu_wrp{
  display: block;
} */



 @media only screen and (max-width: 991.98px) {
  .admin_topNav{ padding: 0;}
.admin_topNav button{ display: block; width: 100%; border-bottom: solid 1px #fff; border-radius: 0;}
 
.responsive_navBar{ display: block; padding-left: 20px;}

.admin_topRight{    display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;}

  .responsive_navBar svg{       background: #25ace3;
    color: #fff;
    border-radius: 5px;
    font-size: 33px;
    padding: 5px 6px; cursor: pointer;}

    .comingsoonText{ min-width:inherit;}
 }


  
  
  @media only screen and (max-width: 640.98px) {
  
      .admin_topbody{ display: block;}
  
      .admin_topbody .adminlogo{ display: block; margin: 0 auto; margin-bottom: 15px; max-width: 90%;}
  
  
      
   }

   @media only screen and (max-width: 480.98px) {
    .admin_topRight{ display: block;}
    .responsive_navBar{        display: block;
      padding-left: 0;
      text-align: center;
      margin-top: 15px;}
   }