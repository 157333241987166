


body {
    font-family: 'Poppins', sans-serif;
     padding: 0!important;
     padding: 0!important;
 }
 
 html body{ margin: 0!important; padding: 0!important;}
 
 h1,
 h2,
 h3,
 h4,
 h5,
 p {
     padding: 0;
     margin: 0;
  
     font-weight: normal;
 }
 
 a {
     text-decoration: none;
 }
 
 button {
     border: 0;
     cursor: pointer;
 }

 

/* ==================LoginForm====================================== */

/* .login_main_wrpper {
    justify-content: center;
    background: rgb(242, 252, 66);
    background: linear-gradient(180deg, rgba(242, 252, 66, 1) 0%, rgba(251, 252, 66, 1) 7%, rgba(66, 113, 252, 1) 100%);
} */

.login_body{ background: rgb(246,251,254);
    background: linear-gradient(135deg, rgba(246,251,254,1) 0%, rgba(175,231,248,1) 51%, rgba(201,240,249,1) 100%); position: relative; width: 100vw; height: 100vh; overflow: hidden;}
.login_page_main{position: absolute; width: 100vw; height: 100vh; z-index: 9; left: 0; top: 0;     display: flex;
    align-items: center;}

.login_bodyBG{width: 100vw; height: 100vh; position: relative;}

.login_bodyBG_block1 {background-color:rgb(180, 236, 255, 0.5);  border-radius: 100%; width: 300px;  height: 300px; position: absolute; left: -60px; top: -110px;
}
.login_bodyBG_block1_sub{   background-color:rgb(16, 166, 216, 0.5);  border-radius: 100%; width: 100px;  height: 100px; position: absolute;  right: -40px; bottom: 30px;}

.login_bodyBG_block2 {   background-color:rgb(40, 184, 233, 0.5);  border-radius: 100%;  width: 300px;   height: 300px;   position: absolute; right: 10%;  top: -110px;  }
    
.login_bodyBG_block2_sub{   background-color:rgb(143, 215, 239, 0.5);   border-radius: 100%;   width: 100px;  height: 100px;  position: absolute; right: 200px;  bottom: -20px;}

 .login_bodyBG_block3 {   background-color:rgb(40, 184, 233, 0.5);   border-radius: 100%;  width: 300px;  height: 300px;   position: absolute;    left: 14%;   bottom: -110px;   }
        
  .login_bodyBG_block3_sub{   background-color:rgb(143, 215, 239, 0.5); border-radius: 100%; width: 100px;  height: 100px; position: absolute; left: 0px; bottom: 230px;}

  .login_bodyBG_block4 {  background-color:rgb(176, 218, 233, 0.5); border-radius: 100%;   width: 300px;  height: 300px; position: absolute;   right: -60px;  bottom: -110px; }
            
.login_bodyBG_block4_sub{  background-color:rgb(16, 166, 216, 0.5);  border-radius: 100%;  width: 100px;  height: 100px;  position: absolute;  right: 260px; bottom: 150px;}


 .login_maindiv{ width: 700px; margin: 0 auto;
  position: relative;
    z-index: 2;
    padding:25px; 
    border-radius: 32px;
    border: 2px solid #fff;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);;
    background: #ffffffaa
}


input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 35px #ffffff inset !important;
    background-color: #000 !important;
}

input:-internal-autofill-selected {
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(255, 255, 255)) !important;
    -webkit-box-shadow: 0 0 0 35px #ffffff inset !important;
}

.login_main_wrpper .forgot_password {
    text-decoration: none;
    color: #1877f2;
    font-weight: 500;
}


.login_maindiv::after{ content: '';  position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 80%;
    left: -10%;
    width: 400px;
    height: 400px;
    -webkit-filter: blur(80px);
    filter: blur(80px);
    background: radial-gradient(circle at 50% 50%, #fff, #444bf700);
    opacity: 0.7;}

    .login_maindiv::before{ content: '';   position: absolute;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 20%;
        right: -40%;
        width: 300px;
        height: 300px;
        -webkit-filter: blur(80px);
        filter: blur(80px);
        background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 1), #4c00ff00);
        opacity: .7;}  
 
 
.loginLogo{ display: block; margin: 0 auto; width: 250px; max-width: 100%;}

.login_maindiv h1{ margin: 0; padding: 15px 0; font-size: 30px; line-height: 30px; text-align: center; color: #103f69; font-weight: bold;}

.login_maindiv .MuiFormControl-root{ background: #fff; border-radius: 4px;}
.login_maindiv .MuiFormControl-root label{ color: #10273d;}
.login_maindiv svg { color: #0282bc;}

.login_maindiv p.Mui-error{      background: #d32f2f;
    color: #fff;
    margin: -2px 0 0 0;
    padding: 4px 4px 0 4px;
    border-radius: 0 0 2px 2px;}

.login_main_wrpper{ position: relative; z-index: 9;}

.login_main_wrpper .passBttn{background: rgb(0,185,254);
    background: linear-gradient(180deg, rgba(0,185,254,1) 0%, rgba(1,102,157,1) 100%); font-size: 26px; color: #fff; font-weight: bold;}

/* ===========================Responsive Elements ============================ */

@media only screen and (max-width: 1199px){


    .login_bodyBG_block3{ left: 0;}
    .login_bodyBG_block3_sub { 
        left: 270px;
        bottom: 120px;
    }

}
@media only screen and (max-width: 900px){
    .login_maindiv{ width: 80%;}

    .login_maindiv{ padding: 15px;}
}

@media only screen and (max-width: 767px){
.login_bodyBG_block1 { 
        width: 150px;
        height: 150px; ;
        left: -40px;
        top: -10px;
    }

.login_bodyBG_block1_sub {
        width: 50px;
        height: 50px;
        left: 70px;
        bottom: -20px;
    }

 
.login_bodyBG_block2 { 
    width: 150px;
    height: 150px; 
    right: 5%;
    top: -30px;
}

.login_bodyBG_block2_sub { 
    width: 50px;
    height: 50px; 
    right: 0px;
    bottom: -20px;
}

.login_bodyBG_block3 { 
    width: 150px;
    height: 150px; 
    left: -20px;
    bottom: -30px;
}


.login_bodyBG_block3_sub { 
    width: 50px;
    height: 50px; 
    left: 130px;
    bottom: 50px;
}

.login_bodyBG_block4 { 
    width: 150px;
    height: 150px; 
    right: -20px;
    bottom: -30px;
}

.login_bodyBG_block4_sub { 
    width: 50px;
    height: 50px; 
    right: 130px;
    bottom: 60px;
}

}
/* @media only screen and (max-width: 900px){
.login_main_wrpper .MuiPaper-root {
    width: 80% !important;
    margin: 0 auto !important;
}
.login_main_wrpper .passBttn {
    background-color: #1877f2;
}


.login_main_wrpper .passBttn span {
    font-size: 20px;
    font-weight: 600;
}

} */

 
 

img {
    border: 0;
    outline: 0;
    max-width: 100%
}

a img {
    outline: none
}

a {
    outline: none;
    cursor: pointer
}

a:active,
a:focus {
    outline: none
}

:active,
:focus {
    outline: none;
    -moz-outline-style: none
}

:link:focus,
:visited:focus {
    outline: none
}

 
 
 
 
  

/* =========================== for admin css ============================ */

.noDataAvailable{ font-size: 24px; padding: 20px 2%; color: #ff0000; display: block; text-align: center; width: 96%;}


.admin_page{background: #e7eef1; min-height: 80vh;     overflow: hidden;}

.admin_h1_class{ background-color: #0070af; padding: 15px; font-size: 24px;  text-align: center;    color: #fff;}
 
.admin_page_wrapper{   margin: 15px;}

.admin_page_wrapper .MuiPaper-root{ box-shadow: none!important; background: none!important;}

.css-pjjt8i-MuiToolbar-root{ min-height: inherit!important; margin:0 !important; padding: 0!important;}


.MuiToolbar-regular {background-color: #0070af!important; padding: 15px; font-size: 24px; min-height: inherit; margin: 0;      
     color: #fff; min-height: inherit!important;}
.MuiToolbar-regular .MuiTypography-h6{    padding: 15px;
        font-size: 24px; color: #fff;}

.MuiToolbar-regular  button{ color: #fff;}

.tablePagination .MuiToolbar-regular {     background: #e5e5e5;
    box-shadow: none;
    border: none;
    min-height: inherit;
    padding: 0;
    color: #000;}

.MuiPaper-elevation{ box-shadow: none!important;}


.MuiTableCell-head{ padding:15px 0!important; font-size: 16px!important;    padding: 0!important;}
.MuiTableCell-body{ padding:15px 0!important;  font-size: 16px!important;   padding: 0!important;}
.listCount{    background: #e5e5e5;
        box-shadow: none;
        border: none;
        min-height: inherit;
        padding: 12px; font-size: 14px;
        color: #000; text-align: center;}


.MuiPagination-root{ border-radius: 0px!important; padding-bottom: 10px;}

.MuiTableHead-root{ background: #042241;}
.MuiTableHead-root th{ color: #fff; font-weight: bold;}
.MuiTableHead-root th svg{ color: #fff!important;}
td.MuiTableCell-body button svg{color: #0070af!important;}

 

.modalblock .MuiBox-root{ border: 10px solid #0070af!important; background-color: #fff; padding: 15px!important;   position: relative!important;   border-radius: 10px; }

.modalimg-block .MuiBox-root{ max-width: auto; min-width: 1200px; border: 10px solid #0070af!important; background-color: #fff; padding: 15px!important;   position: relative!important;   border-radius: 10px; }
.modalimg-block .MuiBox-root button.modalCloseBtn{
    right: -25px;
    top: -25px;
    background: #0070af;
    padding: 2px;
    outline: none;
    color: #fff;  width: auto!important;    position: absolute;
}

.modalblock .MuiBox-root .MuiBox-root{ border: none!important; background: none!important;     padding: 0!important; border-radius: inherit!important;}
.modalblock  .headingtext {
    font-size: 22px;
     font-weight: bold;
    line-height: 24px;
    color: #00adef;
    margin: 0 0 20px 0;
}


.modalblock .modalBTN {
    background: #00b9fe; 
    background: linear-gradient(180deg,rgba(0,185,254,1) 0%,rgba(1,106,161,1) 100%);
    margin: 5px;
    color: #fff;
    font-size: 18px;
    padding: 5px 15px;
    font-weight: bold;
    border-radius: 5px;
    min-width: 80px;
}

 

    .modalblock .MuiBox-root button.modalCloseBtn{
    right: -25px;
    top: -25px;
    background: #0070af;
    padding: 2px;
    outline: none;
    color: #fff;  width: auto!important;    position: absolute;
}


.modalblock .MuiBox-root ul{   padding: 0;}
.modalblock .MuiBox-root ul li{ list-style: none;    background: #ebebeb;   padding: 10px 15px; }

.modalblock .MuiBox-root ul li:nth-of-type(odd){background: #ced9e1;}

.ModalBox_scroll{    display: block;
    margin: 0 -15px;
 
    max-height: 65vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;     overflow-x: hidden}


    tbody .MuiTableRow-root:nth-of-type(odd){ background: #f7f5f5;}   




    .loader-block{    display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0 0 0;}

   


        .loader-block .lds-facebook {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
          }
          .loader-block .lds-facebook div {
            display: inline-block;
            position: absolute;
            left: 8px;
            width: 16px;
            background: #01669d;
            animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          }
          .loader-block .lds-facebook div:nth-child(1) {
            left: 8px;
            animation-delay: -0.24s;
          }
          .loader-block .lds-facebook div:nth-child(2) {
            left: 32px;
            animation-delay: -0.12s;
          }
          .loader-block .lds-facebook div:nth-child(3) {
            left: 56px;
            animation-delay: 0;
          }
          @keyframes lds-facebook {
            0% {
              top: 8px;
              height: 64px;
            }
            50%, 100% {
              top: 24px;
              height: 32px;
            }
          }

 
          .closeModalBtn {
            width: 20px!important;
            height: 20px!important;
            background: #0070af!important;
            color: #fff!important;
            border-radius: 100%!important;
            right: -20px!important;
            position: absolute!important;
            top: -20px!important;
            min-width: inherit!important;
            padding: 15px!important;
        
          }

/* =========================== end  admin css ============================ */


/* ===========================  admin user list css ============================ */

.adminlisttable_v2 table,.adminlisttable_v2 th, .adminlisttable_v2 td { display: block; border: none; min-width: inherit!important;}

.adminlisttable_v2 thead{ display: block; padding: 0; border-radius:0 0 5px 5px;     background: #0091ff;}

.adminlisttable_v2 tr{     display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap; border-radius: 0!important;}

/* .adminlisttable_v2 th:nth-of-type(1){ display: none;} */
.adminlisttable_v2 th:nth-of-type(2){ display: none;}
/* .adminlisttable_v2 td:nth-of-type(1){ display: none;} */
.adminlisttable_v2 td:nth-of-type(2){ display: none;}

.adminlisttable_v2 tr th{      text-align: left!important;    flex: 1 0 32%;     border-bottom: solid 1px #fff;
    padding: 0.5%!important; color: #fff; font-weight: normal;}

    .adminlisttable_v2 td .MuiCheckbox-root, .adminlisttable_v2 th .MuiCheckbox-root{ padding: 0!important;} 

 

.adminlisttable_v2 tbody{ display: block; border: none; padding: 0; min-width: inherit!important;}

.adminlisttable_v2 tbody tr{ 
    background: #ebebeb;
    border: solid 1px #e1e0e0;
    border-radius: 5px;}
  
.adminlisttable_v2 tbody tr td{    flex: 1 0 32%;     
    padding: 0.5%!important;   font-weight: normal;  border-bottom: solid 1px #fff!important;}

 
 .adminlisttable_v2 tbody tr td.description{ overflow: hidden;
        max-width: 75ch;
        text-overflow: ellipsis;
        white-space: nowrap;}




@media only screen and (max-width: 991.98px) {
    .adminlisttable_v2 tr{ display: block;}

    .adminlisttable_v2 tbody tr td{ padding:5px 10px!important; display: block;}
    .adminlisttable_v2 tr th{ padding: 5px 10px!important;  }

    .adminlisttable_v2 tr th:nth-of-type(1){ padding: 5px 2%!important;  width: 96%;}
 }

/* =========================== end  admin user list css ============================ */


.admin_pageList_wrapper .contentshow{
    font-size: 0 !important;
    /* display: block !important; */
    background: url(./assets/image/contenticon.png) no-repeat !important;
    width: 33px !important;
    min-width: 0 !important;
    border-radius: 4px !important;
    margin: 5px !important;
    height: 38px !important;
    background-position: center !important;
    background-size: 33px !important;
    padding: 0 !important;
    }



/*--------------home banner-------*/

.darktheme {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/thankyoubg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-position: center center;
}
.darktheme .content{
    text-align: center;
    background: rgba(255,255,255,0.6);
    padding: 60px;
    box-shadow: 0px 10px 14px rgb(0 0 0 / 10%);
        border: 1px solid #efefef;
        margin: 0 20px;
}
.darktheme .content p{
    font-size: 20px;
    margin: 5px 0;
}

.darktheme .content p strong{
    color: #07547e;
}
.thankyou-block img{
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
} 
.thankyou-container {
    position: relative;
}

@media only screen and (max-width: 480.98px) {
.darktheme .content{
    padding: 40px 20px;
}
.thankyou-block img{width: 260px;}
}

.conflictingSlots .MuiTableCell-body, .conflictingSlots .MuiTableHead-root th{
    padding: 5px !important;
}


.richard_banner .stuker_left_part1 {background: #112e50;}